import React from 'react';
import { Link } from 'react-router-dom';

import styles from './MainStudyPage.module.css';

import { MatrixRain } from '../../components/MatrixRain/MatrixRain';
import { staticLinks } from '../../config/routingsLinks';

const MainStudyPage = () => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <div className={styles.navigator}>
        <div className={styles.navigator_box}>
          <Link className='login' to='/profile/studying'>
            Учёба
          </Link>
          <Link className='login' to='/work/'>
            Работа
          </Link>
          <Link className='login' to='/profile/'>
            Профиль
          </Link>
          <Link className='login' to='/'>
            Выйти
          </Link>
          <div className={styles.gamburger} onClick={() => setOpen(!open)}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className={`${open ? styles.active : styles.menu}`}>
          <Link className='login' to='/profile/studying'>
            Учёба
          </Link>
          <Link className='login' to='/work/'>
            Работа
          </Link>
          <Link className='login' to='/profile/'>
            Профиль
          </Link>
          <Link className='login' to='/'>
            Выйти
          </Link>
        </div>
      </div>
      <div>
        <div className={styles.linkWrapper}>
          <Link className='login' to='/studylist/'>
            Учение.
          </Link>
          <Link className='login' to='/studypage/4'>
            Услуги.
          </Link>
          <Link className='login' to={staticLinks.portfoliopage}>
            Сеть.
          </Link>
        </div>
      </div>
      <MatrixRain></MatrixRain>
    </>
  );
};

export default MainStudyPage;
