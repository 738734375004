import React from 'react';
import { Carousel } from 'react-round-carousel';
import { Link, useNavigate } from 'react-router-dom';

import styles from './Traces.module.css';

import image1 from '../../../../../assets/img/000001.jpg';
import image2 from '../../../../../assets/img/000002.jpg';
import image3 from '../../../../../assets/img/000003.jpg';
import image9 from '../../../../../assets/img/000003.jpg';
import image4 from '../../../../../assets/img/000004.jpg';
import image10 from '../../../../../assets/img/000004.jpg';
import image5 from '../../../../../assets/img/000005.jpg';
import image11 from '../../../../../assets/img/000005.jpg';
import image6 from '../../../../../assets/img/000006.jpg';
import image12 from '../../../../../assets/img/000006.jpg';
import image7 from '../../../../../assets/img/000007.jpg';
import image13 from '../../../../../assets/img/000007.jpg';
import image8 from '../../../../../assets/img/000008.jpg';
import image14 from '../../../../../assets/img/000008.jpg';
import { MatrixRain } from '../../../../../components/MatrixRain/MatrixRain';
import { staticLinks } from '../../../../../config/routingsLinks';
const handleClick = (id) => {
  const element = document.getElementById(`item-${id}`);
  if (!element) return;

  if (element.classList.contains('expanded')) {
    element.classList.remove('expanded');
  } else {
    element.classList.add('expanded');
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

export const items = [
  {
    alt: 'A random photo',
    image: image1,
    content: (
      <div
        id='item-1'
        className='test'
        onClick={() => handleClick(1)}
        style={{ width: '400px', height: '400px' }}
      >
        <img src={image1} alt='A random photo' />
      </div>
    ),
  },
  {
    alt: 'A random photo',
    image: image2,
    content: (
      <div
        id='item-2'
        className='test'
        onClick={() => handleClick(2)}
        style={{ width: '400px', height: '400px' }}
      >
        <img src={image2} alt='A random photo' />
      </div>
    ),
  },
  {
    alt: 'A random photo',
    image: image3,
    content: (
      <div
        id='item-3'
        className='test'
        onClick={() => handleClick(3)}
        style={{ width: '400px', height: '400px' }}
      >
        <img src={image3} alt='A random photo' />
        {/* <strong>Round Carousel</strong>
        <span>Slide number </span> */}
      </div>
    ),
  },
  {
    alt: 'A random photo',
    image: image4,
    content: (
      <div
        id='items-4'
        onClick={() => handleClick(4)}
        style={{ width: '400px', height: '400px' }}
      >
        <img src={image4} alt='A random photo' />
        {/* <strong>Round Carousel</strong>
        <span>Slide number </span> */}
      </div>
    ),
  },
  {
    alt: 'A random photo',
    image: image5,
    content: (
      <div
        onClick={() => handleClick(4)}
        style={{ width: '400px', height: '400px' }}
      >
        <img src={image5} alt='A random photo' />
        {/* <strong>Round Carousel</strong>
        <span>Slide number </span> */}
      </div>
    ),
  },
  {
    alt: 'A random photo',
    image: image6,
    content: (
      <div
        onClick={() => handleClick(4)}
        style={{ width: '400px', height: '400px' }}
      >
        <img src={image6} alt='A random photo' />
        {/* <strong>Round Carousel</strong>
        <span>Slide number </span> */}
      </div>
    ),
  },
  {
    alt: 'A random photo',
    image: image7,
    content: (
      <div
        onClick={() => handleClick(4)}
        style={{ width: '400px', height: '400px' }}
      >
        <img src={image7} alt='A random photo' />
        {/* <strong>Round Carousel</strong>
        <span>Slide number </span> */}
      </div>
    ),
  },
  {
    alt: 'A random photo',
    image: image8,
    content: (
      <div
        onClick={() => handleClick(4)}
        style={{ width: '400px', height: '400px' }}
      >
        <img src={image8} alt='A random photo' />
        {/* <strong>Round Carousel</strong>
        <span>Slide number </span> */}
      </div>
    ),
  },
  {
    alt: 'A random photo',
    image: image9,
    content: (
      <div
        className='test'
        onClick={() => handleClick(3)}
        style={{ width: '400px', height: '400px' }}
      >
        <img src={image9} alt='A random photo' />
        {/* <strong>Round Carousel</strong>
        <span>Slide number </span> */}
      </div>
    ),
  },
  {
    alt: 'A random photo',
    image: image10,
    content: (
      <div
        onClick={() => handleClick(4)}
        style={{ width: '400px', height: '400px' }}
      >
        <img src={image10} alt='A random photo' />
        {/* <strong>Round Carousel</strong>
        <span>Slide number </span> */}
      </div>
    ),
  },
  {
    alt: 'A random photo',
    image: image11,
    content: (
      <div
        onClick={() => handleClick(4)}
        style={{ width: '400px', height: '400px' }}
      >
        <img src={image11} alt='A random photo' />
        {/* <strong>Round Carousel</strong>
        <span>Slide number </span> */}
      </div>
    ),
  },
  {
    alt: 'A random photo',
    image: image12,
    content: (
      <div
        onClick={() => handleClick(4)}
        style={{ width: '400px', height: '400px' }}
      >
        <img src={image12} alt='A random photo' />
        {/* <strong>Round Carousel</strong>
        <span>Slide number </span> */}
      </div>
    ),
  },
  {
    alt: 'A random photo',
    image: image13,
    content: (
      <div
        onClick={() => handleClick(4)}
        style={{ width: '400px', height: '400px' }}
      >
        <img src={image13} alt='A random photo' />
        {/* <strong>Round Carousel</strong>
        <span>Slide number </span> */}
      </div>
    ),
  },
  {
    alt: 'A random photo',
    image: image14,
    content: (
      <div
        onClick={() => handleClick(4)}
        style={{ width: '400px', height: '400px' }}
      >
        <img src={image14} alt='A random photo' />
        {/* <strong>Round Carousel</strong>
        <span>Slide number </span> */}
      </div>
    ),
  },
];

const Traces = () => {
  const navigate = useNavigate();
  const [bookId, setBookId] = React.useState(1);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [scaleBook, setScaleBook] = React.useState(false);
  const carouselRef = React.useMemo(
    () => (node) => {
      if (node !== null) {
        let bookId = (node.getSelectedIndex() + 1) % items.length;
        if (Math.abs(bookId) === 0) bookId = items.length;
        localStorage.setItem('bookId', bookId);
        setBookId(bookId);
      }
    },
    []
  );

  return (
    <>
      <div className={styles.wrapper}>
        <div>{}</div>
        <div className={styles.navigator}>
          <Link>Города.</Link>
          <Link>Сотрудничество.</Link>
          <Link to={staticLinks.trecesollbooks}>Книги.</Link>
          <Link>Кабинет автора.</Link>
        </div>
        <div className={styles.list}>
          <div>
            <Carousel
              itemWidth={200}
              ref={carouselRef}
              items={items}
              slideOnClick
            />
          </div>
          <div className={styles.wrappe}>
            <textarea rows={15} cols={50}>
              Сегодня в читальном зале состоялось необычное для нашей школы
              мероприятие. К нам приехал руководитель Благотворительного Фонда
              СДС и ОУ. Нашим старшеклассникам, предложили участвовать в
              переписи трудов учёных на листы папируса для сохранения информации
              на длительное время. Ключевая особенность папирусной бумаги
              считается долговечность, период полураспада исчисляется до 3000
              лет при бережном хранении. Фонд начал строительство храма книг в
              г. Орске, в котором будут храниться, в том числе и переписанные
              школьниками труды учёных на листах папируса. Наши ребята сегодня
              приняли участие в написании истории, что, надеемся, разбудило
              детские сердца и послужило мотивом к дополнительному получению
              научных знаний. В случае возникновения интереса, напишите, обсудим
              на сколько можем быть интересны друг другу.
              imperiya66.ru@gmail.com
            </textarea>
          </div>
        </div>
        <button onClick={() => navigate(-1)} className={styles.button}>
          {'<--'}Назад
        </button>
        <div className={styles.footer}>Подвал. В разрботке.</div>
      </div>
      <MatrixRain></MatrixRain>
    </>
  );
};

export default Traces;
