import React from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';

import { MatrixRain } from '../../components/MatrixRain/MatrixRain';
import { staticLinks } from '../../config/routingsLinks';
const MainPage = () => {
  return (
    <div>
      <div className='matrix-text'>
        <div style={{ fontSize: '120px', fontWeight: 'bold' }}>
          <Link class='text_matrix' to={staticLinks.planet}>
            Matrix
          </Link>
        </div>
      </div>
      <MatrixRain></MatrixRain>
    </div>
  );
};

export default MainPage;
