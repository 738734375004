import { useFormik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { MatrixRain } from '../../components/MatrixRain/MatrixRain';
import { staticLinks } from '../../config/routingsLinks';
import axiosConfig from '../../utils/axiosConfig';

const RegisterPage = () => {
  const navigate = useNavigate();
  if (localStorage.getItem('token') !== null) {
    navigate('/study');
  }
  const formik = useFormik({
    initialValues: {
      login: '',
      password: '',
    },
    onSubmit: async (values) => {
      const formData = {
        username: values.login,
        password: values.password,
        email: values.login,
      };
      const formdata2 = new FormData();
      formdata2.set('username', 'Пользователь');
      formdata2.set('password', values.password);
      formdata2.set('email', values.login);
      const response = await axiosConfig(1).post(
        'api/users/register/',
        formData,
        { headers: { ContentType: 'application/json' } }
      );
    },
  });
  return (
    <>
      <MatrixRain></MatrixRain>
      <div className='block-login-user'>
        <h3 className='text-auth'>Регистрация</h3>
        <div className='block-form-login'>
          <form onSubmit={formik.handleSubmit}>
            <input
              type='hidden'
              name='csrfmiddlewaretoken'
              defaultValue='lpEFazWWNuYzEICpRE6DbplHv96mYglw4MmNjo5ZzmSUA0afgh4oTxkqnuUoh0OL'
            />
            <div className='block-input'>
              <label htmlFor='email'>Введите почту</label>
              <input
                id='email'
                type='text'
                name='email'
                value={formik.values.login}
                onChange={(e) => formik.setFieldValue('login', e.target.value)}
              />
            </div>
            <div className='block-input'>
              <label htmlFor='pass'>Введите пароль</label>
              <input
                id='pass'
                type='password'
                name='pass'
                value={formik.values.password}
                onChange={(e) =>
                  formik.setFieldValue('password', e.target.value)
                }
              />
            </div>
            <div className='select_role'>
              <h3>Ваше имя: "Пользователь"</h3>
              <p>Возможные имена:</p>
              <select name='' id=''>
                <option value=''>Пользователь.</option>
                <option value=''>Десятник.</option>
                <option value=''>Пятидесятник.</option>
                <option value=''>Сотник.</option>
                <option value=''>Тысячник.</option>
                <option value=''>Десятитысячник.</option>
                <option value=''>Стотысячник.</option>
                <option value=''>Миллионник.</option>
                <option value=''>Судья.</option>
                <option value=''>Ключник.</option>
                <option value=''>Безопасность.</option>
              </select>
            </div>
            <div className='politic'>
              <label htmlFor='politic'>
                <input
                  type='checkbox'
                  id='politic'
                  defaultValue='Политика конфиденциальности'
                />
                <a href='#' className='small-text politic'>
                  <small>Политика конфиденциальности</small>
                </a>
              </label>
            </div>
            <button type='submit' className='btn-login'>
              Зарегестрироваться
            </button>
          </form>
          <div className='block-with-text-reg-acc'>
            <p className='text-create-acc'>
              Если уже есть аккаунт, ты можешь авторизоваться
            </p>
            <div>
              <Link to={staticLinks.login} className='small-text'>
                <small>Авторизоваться</small>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterPage;
