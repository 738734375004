import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import styles from './StudyPage.module.css';

import { MatrixRain } from '../../components/MatrixRain/MatrixRain';
import { dynamicLinks } from '../../config/routingsLinks';
import axiosConfig from '../../utils/axiosConfig';

const studies = {
  1: 'Всевышний',
  2: 'Природа',
  3: 'Культура',
  4: 'Наука',
};

const studiesArr = [
  { id: 1, title: 'Всевышний.' },
  { id: 2, title: 'Природа.' },
  { id: 3, title: 'Культура.' },
  { id: 4, title: 'Наука.' },
];

function StudyPage() {
  const navigate = useNavigate();
  const [blocks, setBlocks] = useState([]);

  const getData = async () => {
    const response = await axiosConfig(2).get('survey/api/blocks/');
    if ('data' in response) {
      setBlocks(response.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <button onClick={() => navigate(-1)} className={styles.button}>
        {'<--'}Назад
      </button>{' '}
      <h1 className={styles.title}>Выбирите тему обучения.</h1>
      <div className={styles.wrapper}>
        {blocks?.map((item) => (
          <Link to={`${dynamicLinks.studypage(item.id)}`}>{item.text}</Link>
        ))}
      </div>
      <MatrixRain></MatrixRain>
    </>
  );
}

export default StudyPage;
