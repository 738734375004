import React from 'react';
import { useNavigate } from 'react-router';

import styles from './LeadProgect.module.css';

import { MatrixRain } from '../../../../../../components/MatrixRain/MatrixRain';

const LeadProgect = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className={styles.wrappe}>
        <textarea>
          Данная с страница на этапе формирования. В случае возникновения
          интереса, напишите, обсудим на сколько можем быть интересны друг
          другу.imperiya66.ru@gmail.com
        </textarea>
      </div>
      <button onClick={() => navigate(-1)} className={styles.button}>
        {'<--'}Назад
      </button>{' '}
      <MatrixRain></MatrixRain>
    </div>
  );
};

export default LeadProgect;
