import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Books.module.css';

import { MatrixRain } from '../../../../../../components/MatrixRain/MatrixRain';
import { dynamicLinks } from '../../../../../../config/routingsLinks';

const Books = () => {
  return (
    <div className={styles.wrapper}>
      <Link to={dynamicLinks.trecesbooks(1)}>Книга 1</Link>
      <Link to={dynamicLinks.trecesbooks(2)}>Книга 2</Link>
      <Link to={dynamicLinks.trecesbooks(3)}>Книга 3</Link>
      <Link to={dynamicLinks.trecesbooks(1)}>Книга 4</Link>
      <Link to={dynamicLinks.trecesbooks(2)}>Книга 5</Link>
      <Link to={dynamicLinks.trecesbooks(3)}>Книга 6</Link>
      <MatrixRain></MatrixRain>
    </div>
  );
};

export default Books;
