import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { MatrixRain } from '../../../components/MatrixRain/MatrixRain';
import { ProgressBar } from '../../../components/ProgressBar/ProgressBar';
import axiosConfig from '../../../utils/axiosConfig';

import styles from './ThemePage.module.scss';

const ThemePage = () => {
  const { id, childid } = useParams();
  console.log(id, childid);
  const [test, setTest] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [questions, setQuestions] = useState([]);

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [currentUserAnswer, setCurrentUserAnswer] = useState('');
  const [isFinish, setIsFinish] = useState(false);
  const [userAnswers, setUserAnswers] = useState([]);

  const getTest = async () => {
    setIsLoading(true);
    const response = await axiosConfig(2).get(`survey/api/tests/${childid}/`);

    if ('data' in response) {
      setTest(response.data);
      setQuestions(response.data.questions);
    }

    const responseAnswers = await axiosConfig(2).get(
      `survey/api/tests/${childid}/answers/`
    );

    if ('data' in responseAnswers) {
      setTest(responseAnswers.data);
      setQuestions(responseAnswers.data.questions);
    }

    setIsLoading(false);
  };
  const checkAnswer = async (answer) => {
    setIsLoading(true);

    const response = await axiosConfig(2).get(
      `survey/api/answers/${answer.id}/check/`
    );

    if ('data' in response) {
      const isCorrect = response.data.is_correct;
      setCurrentUserAnswer(answer.text);
      setUserAnswers([...userAnswers, answer]);
      if (currentQuestion !== questions.length - 1) {
        setCurrentQuestion(currentQuestion + 1);
      } else {
        setIsFinish(true);
      }
    }
    setIsLoading(false);
    console.log(userAnswers);
  };

  useEffect(() => {
    getTest();
  }, []);

  const getButtonClasses = (value) => {
    return clsx({
      [styles.radio]: true,
      [styles.active]: value === currentUserAnswer,
    });
  };

  return (
    <>
      <div className={styles.testWrapper}>
        {isFinish ? (
          <>
            <h2 className={styles.title}>Тест окончен</h2>
            {userAnswers.map((item, index) => (
              <p
                className={item.is_correct ? styles.correct : styles.inCorrect}
              >
                <span>{questions[index].text}</span>
                <br /> {item.text}
              </p>
            ))}
          </>
        ) : (
          <>
            <h2 className={styles.title}>{test?.text}</h2>
            <p className={styles.subTitle}>
              {questions[currentQuestion]?.text}
            </p>
            <ProgressBar
              currentQuestion={currentQuestion + 1}
              countOfUserAnswers={userAnswers.length}
              countOfQuestions={questions.length}
            />
            <div className={styles.answers}>
              {questions[currentQuestion]?.answers?.map((answer) => (
                <button
                  disabled={isLoading}
                  key={answer.value}
                  onClick={() => checkAnswer(answer)}
                >
                  <div className={getButtonClasses(answer.text)} />
                  {answer.text}
                </button>
              ))}
            </div>
          </>
        )}
      </div>
      <MatrixRain></MatrixRain>
    </>
  );
};

export default ThemePage;
