import React from 'react';
import { Link } from 'react-router-dom';

import styles from './PlanetPage.module.css';

import CommandmentsList from '../../components/CommandmentsList/CommandmentsList';
import VerdictList from '../../components/VerdictList/VerdictList';
import { staticLinks } from '../../config/routingsLinks';

const PlanetPage = () => {
  const [open, setOpen] = React.useState(false);

  const verdictArr = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
    { id: 7 },
    { id: 8 },
    { id: 9 },
    { id: 10 },
  ];
  return (
    <>
      <div>
        <div className={styles.navigator}>
          <div className={styles.navigator_box}>
            <Link to={'/'}>Остаться в мире</Link>
            <Link to={'/login'}>Войти в матрицу</Link>
            <Link to={staticLinks.study}>На сайт</Link>
            <div className={styles.gamburger} onClick={() => setOpen(!open)}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div className={styles.menu + `${open ? ' active' : ''}`}>
            <Link to={'/'}>Остаться в мире</Link>
            <Link to={'/login'}>Войти в матрицу</Link>
            <Link to={staticLinks.study}>На сайт</Link>
          </div>
        </div>

        {/* <div id="planet" ref={planetRef}></div>*/}
        <div className={styles.list}>
          <VerdictList verdict={verdictArr}></VerdictList>
          <CommandmentsList commandments={verdictArr}></CommandmentsList>
        </div>
      </div>
    </>
  );
};

export default PlanetPage;
