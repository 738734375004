import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import styles from './About.module.css';

const About = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <button onClick={() => navigate('/fond/1/')} className={styles.button}>
          {'<--'}Назад
        </button>
      </div>
      <p className={styles.wrappe}>
        Благотворительный Фонд "СДС и ОУ" Полное название: Благотворительный
        Фонд "Содействия строительству детских садов и образовательных
        учреждений" <p />
        Фонд создан в 2015 году. Непосредственная цель отображена в названии
        Фонда. Основные направления работы фонда: Содействия профилактики и
        охраны здоровья граждан, пропаганды здорового образа жизни, улучшения
        морально-психологического состояния граждан.
        <br />
        Содействие в социальной реабилитации детей находящихся в трудной
        жизненной ситуации. <br />
        Своими словами добавлю. За девять лет работы фонда, мы не много чего
        сделали, во всяком случаем мы не сможем похвастаться так как нам этого
        хочется. Работа фонда на протяжении своего существования заключалась и в
        основном сейчас заключается, в рассылке писем по разным правительствам и
        тем, кто имеет к правительствам отношения, партии, все силовые ведомства
        и разные люди. Угашать сердца, отрезвлять горячих, корректировать
        путников, воодушевлять уставших. Это ключевая деятельность фонда. <br />
        На данном этапе фонд запустили три проекта. "Матрица"
        <Link to={'/fond/1/ProjectsFond/'} target='_blank'>
          Следы Эпох.
        </Link>
        "Престол Давида" <br />
        "Матрица" – это контент, орудие, благодаря которому нам дана возможность
        использовать учение во благо человечества. Проектом хотим показать
        молодёжи что такое матрица и чем она отличается от реальности. Далее
        собрав в матрице полезную информацию воспитать в людях милосердие и
        наполнив сердца любовью, вывести из колыбели. Любовь! Что есть "любовь"?
        Вы спросите: Я отвечу – вера! В чём вера выражается, в Воле Иисуса
        Христа! В чём Воля Христа, в заповедях! Но если вера, не имеет дел, то
        она мертва сама по себе. То, как мы заботимся о нуждающихся, является
        отражением нашей любви ко Христу и нашей позиции к собственным детям и
        людям. Говорю вам! Любите всё и не вам судить кого бы там ни было,
        максимум в изоляцию и оттуда пусть идут к встрече. Любовь, единственный
        артефакт, который является связующим между всеми. Наше воображение
        настолько велико, и надежда настолько огромна, что мы создаём самые
        сказочные и невероятные утопии, которые можно представить, уповая на
        Иисуса Христа. В наших мирах, сегодняшние проблемы не только можно
        преодолеть, но и решить, одним мгновением. Для этого достаточно спрятать
        меч в ножны и подставить другую щёку под удар. В утопиях кроется наша
        способность видеть все неправильное, как нами содеянное, и все
        правильное, как благоговение. Данные проекты является неотъемлемыми
        механизмами в цепочке ряда проектов, целью которых является
        строительство царствия на земле в пределах города Орска. Более подробно
        я описал свои действия в своих книгах, но книги уже другая история. И
        так. Матрица! Портал для разного рода информации от Всевышнего до
        механизмов, которыми изучать законы природы и возделывать данное нам
        Отцом. Хочу чтобы вас не пугала моя фанатичность в вере, я просто живу
        по законам мира и призываю вас окунутся в реалии которые Предлагает вам
        наш Отец по средствам моих проектов. Второй проект. "Следы Эпох" Фонд
        создал библиотеку из листов папируса. Листы папируса будут заполнять
        дети и люди. Когда листы заполнены мы сшиваем их в книги. Сканы листов
        размещаются на наших ресурсах и распространяются в свободной форме.
        Книги хранятся в библиотеке фонда, в городе Орске. Суть проекта в
        следующем. Мы обращаемся к школьникам с просьбой переписать заранее
        согласованные с советом и руководством школы труды учёных и выдающихся
        деятелей на листы папируса по средствам мероприятий, определённые работы
        по средствам голосования будут передаваться в библиотеку фонда с
        согласия детей и собираться в смысловые книги для будущих поколений. На
        листах будет написана предыстория появления, имена авторов и
        организаторов. Мы ищем сотрудничество и помощь. Помощь в следующем.
        Нужно прийти в школу. Встретиться с директором школы. Сказать директору
        кто мы. Далее, сказать что мы от них хотим. Египетский папирус, привозят
        наши друзья из Каира. Папирус и специальные ручки фонд предоставит
        безвозмездно. Немного о растении. Папирус растёт вдоль реки Нил на
        заболоченных кустарных участках. Период полураспада папируса составляет
        несколько тысяч лет. С позволения наших друзей мы, открываем новую
        страницу этого растения в России. Вопрос к вам! Вы когда-нибудь
        помышляли как сохранить свои знания для грядущих поколений? Как повлиять
        на ход развития цивилизации и войти в историю? Задумывались ли вы как
        можно создать летопись для всей семьи, сохранить наследие для потомков,
        или ваши знания, труды? Думаю, задумывались, во всяком случае, моё
        послание тем, кто меня слышит и разделяет мои интересы. Где можно
        использовать это растение? Папирус идеально подойдёт для подарков, или в
        тубусах. Идеально подойдёт для нанесения разных рисунков и переноса
        фотографий любых размеров. Идеально подойдёт для школьных мероприятий от
        благотворительных фондов в виде проведения диктантов. Идеально подойдёт
        для создания книг разных размеров и форм. Сейчас папирус — это
        путешествие в прошлое, формируя будущее в настоящем! И это реальность
        которой вы все свидетели. Свидетели зарождения нового. Если вы хотите
        оставить после себя память, если вы хотите сделать послание для
        потомков, папирус — достойный и единственный выбор! Представьте другой
        момент! Во время переписи, ребята наверняка будут допускать ошибки, по
        итогам чего одну и ту же информацию они будут переписывать по несколько
        раз в живую с горящими сердцами, движимые итогом. И переписывая, разная
        информация будет формироваться у будущих учёных отражаясь мощным эхом в
        их менталитете, что поспособствует в выборе будущей профессии на благо
        мира. Мы это думаем так и приглашаем тех, кто разделяет наши интересы!
      </p>
    </div>
  );
};

export default About;
