import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import styles from './WorkPage.module.css';

import { MatrixRain } from '../../components/MatrixRain/MatrixRain';
import { staticLinks } from '../../config/routingsLinks';

export const WorkPage = () => {
  const [isVizible, setIsVizible] = useState(false);
  const navigate = useNavigate();
  function hendleopenmodal() {
    setIsVizible(!isVizible);
  }
  return (
    <>
      {isVizible && (
        <div className={styles.modal}>
          <Link to={staticLinks.main}> В матрицу </Link>
          <Link to={'/work/'}>Работа.</Link>
          <Link to={'/profile/'}>Профиль.</Link>
          <Link> Сеть. </Link>
          <Link> Финансы. </Link>
        </div>
      )}
      <div className={styles.wrapper}>
        <button onClick={() => hendleopenmodal()} className={styles.menubutton}>
          <span></span>
          <span></span>
          <span></span>
        </button>{' '}
        <Link to={staticLinks.main}> В матрицу </Link>
        <Link to={'/work/'}>Работа.</Link>
        <Link to={'/profile/'}>Профиль.</Link>
        <Link> Сеть. </Link>
        <Link> Финансы. </Link>
      </div>
      <MatrixRain></MatrixRain>
      <div className={styles.linkList}>
        <Link to={'/fond/1/LightPage'}>ООО "Свет".</Link>
        <Link to={'/fond/1'}>Благотворительный Фонд "СДС и ОУ".</Link>
      </div>
      <button onClick={() => navigate(-1)} className={styles.button}>
        {'<--'}Назад
      </button>{' '}
      <div className={styles.textarea}>
        <textarea>
          Данная страница на этапе формирования. В случае возникновения
          интереса, напишите, обсудим на сколько можем быть интересны друг
          другу.imperiya66.ru@gmail.com Вопросы по папирусу вы можете направить
          на эту почту, я на связи. Хочу, чтобы вы понимали, я за развитие науки
          с культурой и хочу, чтобы ваши творения жили тысячелетия, в том числе
          хочу, чтобы вы ими любовались вечно, как и те, кто будет рядом с вами.
          При этом помните, всяк престол, утверждается Истиной. Не переступайте
          закон, пощады не будет!!! Учитесь жить вечно. Не надо слушать кесарей.
          Кесарь = дьявол = фараоны = отцы = президенты = цари = князья = многие
          патриархи = священники раскольники, это всё дьявола!!! за которыми ад
          и ничего более. Не позволяйте им питать вас сказками создавая иллюзии
          в ваших мыслях, а всё это подпитывать фоном разных тем сбивая вас с
          пути в сторону Истины!!!! Боритесь и боритесь, и клянусь вам Именем
          Иисуса Христа, вы начнёте видеть.
        </textarea>
      </div>
    </>
  );
};
