import { useFormik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { MatrixRain } from '../../components/MatrixRain/MatrixRain';
import { staticLinks } from '../../config/routingsLinks';
import axiosConfig from '../../utils/axiosConfig';

const LoginPage = () => {
  const navigate = useNavigate();
  if (localStorage.getItem('token') !== null) {
    navigate('/study');
  }

  const formik = useFormik({
    initialValues: {
      login: '',
      password: '',
    },
    onSubmit: async (values) => {
      const formData = {
        username: values.login,
        password: values.password,
      };
      const formdata2 = new FormData();
      formdata2.set('username', values.login);
      formdata2.set('password', values.password);
      const response = await axiosConfig(1).post('api/users/auth/', formdata2);
      if ('data' in response) {
        const data = response.data;
        console.log(data.auth_token);
        localStorage.setItem('token', data.auth_token);
      }
    },
  });
  return (
    <>
      <MatrixRain></MatrixRain>
      <div className='block-login-user'>
        <h3 className='text-auth'>Авторизация</h3>
        <div className='block-form-login'>
          <form onSubmit={formik.handleSubmit}>
            <br />
            <>
              <label htmlFor='textArea' className='label-auth'>
                Почта
              </label>
              <br />
              <input
                type='email'
                name='username'
                id='textArea'
                className='login'
                maxLength={254}
                required=''
                value={formik.values.login}
                onChange={(e) => formik.setFieldValue('login', e.target.value)}
              />
              <br />
              <label htmlFor='textArea2' className='label-auth'>
                Пароль
              </label>
              <br />
              <input
                type='password'
                name='password'
                cols={40}
                rows={6}
                id='textArea2'
                className='password'
                required=''
                value={formik.values.password}
                onChange={(e) =>
                  formik.setFieldValue('password', e.target.value)
                }
              />
              <p />
            </>
            {/* {f}
          <p>{f.errors}</p> */}
            <button className='btn-login' type='submit'>
              Войти
            </button>
          </form>
          <div>{/* <p>{error}</p> */}</div>
          <div className='block-with-text-reg-acc'>
            <p className='text-create-acc'>
              Ещё нет аккаунта?
              <br /> Создай его, нажав на ссылку ниже
            </p>
            <Link to={staticLinks.register} className='small-text'>
              <small>Зарегестрировать аккаунт</small>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
