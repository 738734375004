export const BOOKS_TEXT = {
  1: [
    {
      leftPage: `<div>
                <div>
                  <p>
                    <h1>Книга "Ключи"</h1>
                  </p>
                </div>`,
      rightPage: `<div>
                      <center>
                        <h1>
                          Любовь. Фундаментальная бесструктурная частица,
                          являющаяся основой сущего. Связующее, между нами.
                        </h1>
                      </center>
                </div>`,
    },
    {
      leftPage: `<div>
<table>
  <thead>
    <tr>
      <th scope="col"><a target="_blank" href="https://000000000000.ru/"></a>ФИО</th>
      <th scope="col">Дата приговора.</th>
      <th scope="col">Документы.</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">Савва.</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">111111</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">222222</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
  </tbody>
</table>
                </div>`,
      rightPage: `<div>
<table>
  <thead>
    <tr>
      <th scope="col"><a target="_blank" href="https://000000000000.ru/"></a>ФИО</th>
      <th scope="col">Дата приговора.</th>
      <th scope="col">Документы.</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">Савва.</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">111111</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">222222</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
  </tbody>
</table>
                </div>`,
    },
  ],
  2: [
    {
      leftPage: `<div>
                  <p>
                    <h1>Книга жизни.</h1>
                  </p>
                </div>`,
      rightPage: `<div>
                  <h1>
                  10.	Книга десять. Вердикт десять.
                  Друзья разбойника. Жители города. Праведники. Сто сорок четыре тысячи девственников мужского пола, тех, кто согласились идти за Мной. Запечатлённые. Помилованные.
                  </h1>
                </div>`,
    },
    {
      leftPage: `<div>
<table>
  <thead>
    <tr>
      <th scope="col"><a target="_blank" href="https://000000000000.ru/"></a>ФИО</th>
      <th scope="col">Дата приговора.</th>
      <th scope="col">Документы.</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">Савва.</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">111111</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">222222</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
  </tbody>
</table>
                </div>`,
      rightPage: `<div>
                  <h1>Священики освобождаются от прощения всех грехов. Игнор, в ад</h1>
<table>
  <thead>
    <tr>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">222222</a></td>
    </tr>
  </tbody>
</table>
                </div>`,
    },
  ],
  3: [
    {
      leftPage: `<div>
                    <h1>Вердикт 1</h1>
                </div>`,
      rightPage: `<div>
                <h1>
                Первая книга. Вердикт один.
                Убийству нет прощения. Противящийся злу, дополняет зло. Убийство во имя защиты, правды, отечества, есть нарушение. Убийство во Имя Моё, есть нарушение. Прощения за это нет и не будет. Простил убийство, стал убийцей. 
                Самоубийство - тоже убийство. 
                Пособничество в убийстве, убийство. 
                Отправил убивать, стал убийцей. 
                На земле нет того, кто простит убийство!
                </h1>
                </div>`,
    },
    {
      leftPage: `<div>
<table>
  <thead>
    <tr>
      <th scope="col"><a target="_blank" href="https://000000000000.ru/"></a>ФИО</th>
      <th scope="col">Дата приговора.</th>
      <th scope="col">Документы.</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">Савва.</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">111111</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">222222</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
  </tbody>
</table>
                </div>`,
      rightPage: `<div>
<table>
  <thead>
    <tr>
      <th scope="col"><a target="_blank" href="https://000000000000.ru/"></a>ФИО</th>
      <th scope="col">Дата приговора.</th>
      <th scope="col">Документы.</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">Савва.</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">111111</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">222222</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
  </tbody>
</table>
                </div>`,
    },
  ],
  4: [
    {
      leftPage: `<div>
                <p>
                <h1>Это Вердикт 2.</h1>
                </p>
                <h1>Священики освобождаются от прощения всех грехов.</h1>
                </div>`,
      rightPage: `<div>
                <p>
                <h1> Вторая книга. Вердикт два. Разводящиеся без вины прелюбодеяния. 
                Разведённые, без вины прелюбодеяния в ад. Обручающиеся с разведёнными по вине прелюбодеяния, запрещено! Рукоблудники, всяк извращённый, извращенец и сменивший пол, горите там же где и убийцы.  
                </h1>
                </p>
                </div>`,
    },
    {
      leftPage: `<div>
<table>
  <thead>
    <tr>
      <th scope="col"><a target="_blank" href="https://000000000000.ru/"></a>ФИО</th>
      <th scope="col">Дата приговора.</th>
      <th scope="col">Документы.</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">Савва.</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">111111</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">222222</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
  </tbody>
</table>
                </div>`,
      rightPage: `<div>
<table>
  <thead>
    <tr>
      <th scope="col"><a target="_blank" href="https://000000000000.ru/"></a>ФИО</th>
      <th scope="col">Дата приговора.</th>
      <th scope="col">Документы.</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">Савва.</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">111111</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">222222</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
  </tbody>
</table>
                </div>`,
    },
  ],
  5: [
    {
      leftPage: `<div>
                <h1>Вердикт 3.</h1>
                <h1>Священники освобождаются от прощений всяких грехов.</h1>
                </div>`,
      rightPage: `<div>
                <h1>Третья книга. Вердикт три.
                Нанесение рисунков под кожу (Татуировки или ещё, какое название выдумаете), запрещено. Пьющие спиртное, хоть самую малость, запрещено. 
                Курящие, употребляющие всякое дурманящее, матершинники, сквернословящие, пререкающиеся, спорящие, туда-же, где и разведённые по вине прелюбодеяния. 
                </h1>
                </div>`,
    },
    {
      leftPage: `<div>
<table>
  <thead>
    <tr>
      <th scope="col"><a target="_blank" href="https://000000000000.ru/"></a>ФИО</th>
      <th scope="col">Дата приговора.</th>
      <th scope="col">Документы.</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">Савва.</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">111111</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">222222</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
  </tbody>
</table>
                </div>`,
      rightPage: `<div>
<table>
  <thead>
    <tr>
      <th scope="col"><a target="_blank" href="https://000000000000.ru/"></a>ФИО</th>
      <th scope="col">Дата приговора.</th>
      <th scope="col">Документы.</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">Савва.</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">111111</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">222222</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
  </tbody>
</table>
                </div>`,
    },
  ],
  6: [
    {
      leftPage: `<div>
                  <h1>Это Вердикт 4.</h1>
                  <h1>Священники освобождаются от прощений всяких грехов.</h1>
                </div>`,
      rightPage: `<div>
                  <h1>
                  4. Четвёртая книга. Вердикт четыре. Колдуны. Чародеи. Гадалки. Астрологи. Маги. Духовызыватели, все в гиену огненную к отцу своему.
                  </h1>
                </div>`,
    },
    {
      leftPage: `<div>
<table>
  <thead>
    <tr>
      <th scope="col"><a target="_blank" href="https://000000000000.ru/"></a>ФИО</th>
      <th scope="col">Дата приговора.</th>
      <th scope="col">Документы.</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">Савва.</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">111111</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">222222</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
  </tbody>
</table>
                </div>`,
      rightPage: `<div>
<table>
  <thead>
    <tr>
      <th scope="col"><a target="_blank" href="https://000000000000.ru/"></a>ФИО</th>
      <th scope="col">Дата приговора.</th>
      <th scope="col">Документы.</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">Савва.</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">111111</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">222222</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
  </tbody>
</table>
                </div>`,
    },
  ],
  7: [
    {
      leftPage: `<div>
              <h1>Это Вердикт 5.</h1>
              <h1>Священники освобождаются от прощений всяких грехов.</h1>
                </div>`,
      rightPage: `<div>
                <h1>5. Пятая книга. Вердикт пять. Фараоны. Цари. Князья. Прочие главенствующие. Президенты. Главы земель (Страны). 
                </h1>
                </div>`,
    },
    {
      leftPage: `<div>
<table>
  <thead>
    <tr>
      <th scope="col"><a target="_blank" href="https://000000000000.ru/"></a>ФИО</th>
      <th scope="col">Дата приговора.</th>
      <th scope="col">Документы.</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">Савва.</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">111111</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">222222</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
  </tbody>
</table>
                </div>`,
      rightPage: `<div>
<table>
  <thead>
    <tr>
      <th scope="col"><a target="_blank" href="https://000000000000.ru/"></a>ФИО</th>
      <th scope="col">Дата приговора.</th>
      <th scope="col">Документы.</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">Савва.</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">111111</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">222222</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
  </tbody>
</table>
                </div>`,
    },
  ],
  8: [
    {
      leftPage: `<div>
                <h1>Это Вердикт 6.</h1>
                <h1>Священники освобождаются от прощений всяких грехов.</h1>
                </div>`,
      rightPage: `<div>
                <h1> 6.	Шестая книга. Вердикт шесть. Лжепророки. Иконы, ложные боги (Материальное). Лжеотцы: Опекунов и кровных отцов, можно называть отцами, отец.
                Лжеучителя: Дающие советы как жить людям. Лжесвященники: Патриархи, их замы, все нарушители в длинных одеждах. Раскольники: Все те, кто виновен в расколе.
                </h1>
                </div>`,
    },
    {
      leftPage: `<div>
<table>
  <thead>
    <tr>
      <th scope="col"><a target="_blank" href="https://000000000000.ru/"></a>ФИО</th>
      <th scope="col">Дата приговора.</th>
      <th scope="col">Документы.</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">Савва.</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">111111</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">222222</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
  </tbody>
</table>
                </div>`,
      rightPage: `<div>
<table>
  <thead>
    <tr>
      <th scope="col"><a target="_blank" href="https://000000000000.ru/"></a>ФИО</th>
      <th scope="col">Дата приговора.</th>
      <th scope="col">Документы.</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">Савва.</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">111111</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">222222</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
  </tbody>
</table>
                </div>`,
    },
  ],
  9: [
    {
      leftPage: `<div>
              <h1>Вердикт 7.</h1>
              <h1>Священники освобождаются от прощений всяких грехов.</h1>
                </div>`,
      rightPage: `<div>
                <h1>7.	Седьмая книга. Вердикт семь. Лжерелигии: Индуиская. Мусульманская. Иудейская. Католики. Православные. Старообрядцы. Всех напишите, кто потакал малейшему злу.
                </h1>
                </div>`,
    },
    {
      leftPage: `<div>
                  <table>
  <thead>
    <tr>
      <th scope="col"><a target="_blank" href="https://000000000000.ru/"></a>ФИО</th>
      <th scope="col">Дата приговора.</th>
      <th scope="col">Документы.</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">Савва.</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">111111</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">222222</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
  </tbody>
</table>
                </div>`,
      rightPage: `<div>
<table>
  <thead>
    <tr>
      <th scope="col"><a target="_blank" href="https://000000000000.ru/"></a>ФИО</th>
      <th scope="col">Дата приговора.</th>
      <th scope="col">Документы.</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">Савва.</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">111111</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">222222</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
  </tbody>
</table>
                </div>`,
    },
  ],
  10: [
    {
      leftPage: `<div>
                  <h1>Вердикт 8</h1>
                  <h1>Священники освобождаются от прощения всех грехов.</h1>
                </div>`,
      rightPage: `<div>
                  <h1>8. Восьмая книга. Вердикт восемь. В эту книгу всех Николаитов. Производители спиртного и всякого дурманящего, делающих вещей для извращенцев. Напишите прочих несущих злодейства. По словам разбойника, банкиры, заводы, отравляющие природу, землю и детей Моих. Всех в эту книгу.
                  </h1>
                </div>`,
    },
    {
      leftPage: `<div>
                    <h1> Запись первая. День начала конца от 
                    Рождества Отца. 07.04.2024 год. Город Орск.
                    </h1>
                </div>`,
      rightPage: `<div>
      <table>
  <thead>
    <tr>
      <th scope="col"><a target="_blank" href="https://000000000000.ru/"></a>ФИО</th>
      <th scope="col">Дата приговора.</th>
      <th scope="col">Документы.</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">Савва.</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">111111</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">222222</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
  </tbody>
</table>
                </div>`,
    },
  ],
  11: [
    {
      leftPage: `<div>
                <h1>Это Вердикт 9.</h1>
                <h1>Священники освобождаются от прощений всяких грехов.</h1>
                </div>`,
      rightPage: `<div>
                <h1>9. Книга девять. Вердикт девять. Мясоеды, людоеды. Убийцы флоры и фауны. 
                </h1>
                </div>`,
    },
    {
      leftPage: `<div>
      <table>
  <thead>
    <tr>
      <th scope="col"><a target="_blank" href="https://000000000000.ru/"></a>ФИО</th>
      <th scope="col">Дата приговора.</th>
      <th scope="col">Документы.</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">Савва.</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">111111</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">222222</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
  </tbody>
</table>
                </div>`,
      rightPage: `<div>
      <table>
  <thead>
    <tr>
      <th scope="col"><a target="_blank" href="https://000000000000.ru/"></a>ФИО</th>
      <th scope="col">Дата приговора.</th>
      <th scope="col">Документы.</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">Савва.</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">111111</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">222222</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
        <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">333333</a></td>
    </tr>
    <tr>
      <th scope="row">Савва</th>
      <td>20.04.2024</td>
      <td><a target="_blank" href="https://000000000000.ru/">444444</a></td>
    </tr>
  </tbody>
</table>
                </div>`,
    },
  ],
  12: [
    {
      leftPage: `<div>
              <H1>Вечный Завет.</H1>
                </div>`,
      rightPage: `<div>
                  <h1>
                Любовь. Фундаментальная бесструктурная частица,
                являющаяся основой сущего. Связующее, между нами.
                  </h1>
                </div>`,
    },
    {
      leftPage: `<div>
                <h2>Всевышний.</h2>
                <h4> Для начала, давайте поговорим О Боге.
                  Всевышний — Это не старичок с бородой, Которого иногда рисуют люди,
                  находящиеся на уровне развития неандертальцев. Бог — изначально
                  это не человек. Это Разум Который Создал всё, что нас окружает и что
                  находится в нас, всё, что мы в состоянии представить и то, что не можем
                  вообразить. У Него нет пределов, нет границ!
                  </h4>
                </div>`,
      rightPage: `<div>
                  <h4>Бог Создал космос и всё, что в нём находится. Сотворил человека
                  посредством эволюции. Для Него мы акт Творения, люди — результат
                  Этого акта! Бог — это Разум и Этот Разум Думает. Этот Разум Размышляет.
                  У Разума Есть Интеллект. Разум, Живой и Он Везде, Вездесущ! Бог — Это Процесс, Само Действие, Сам Процесс, Именно, как Таковой. </h4>
                </div>`,
    },
    {
      leftPage: `<div>
                  <h3>Он Дал нам фундамент мира, заповеди на горе Синай. Он Не Был человеком,
                  но Воплотившись в человеке, Стал Им. Разум, Принял такое решение,
                  чтобы сблизиться с актом Своего Творения, чтобы мы могли понять Его,
                  услышать Его Слова. Он Пришёл к нам, Именем Иисус Христос. </h3>
                </div>`,
      rightPage: `<div>
                  <h4>И Он, Создал
                  мир и Создал Царствие Небесное. Почему Разум Назвался Сыном? Это было
                  сделано, чтобы нам легче было Понимать И Воспринимать Его. После,
                  Показав, как пользоваться заповедями, Он Ушёл Оставив Учение Своим
                  ученикам.</h4>
                  </div>`,
    },
  ],
};
