import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import styles from './StudyPlanPage.module.css';

import { MatrixRain } from '../../components/MatrixRain/MatrixRain';
import axiosConfig from '../../utils/axiosConfig';

function StudyPlanPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentId, setCurrentId] = useState(id);
  const [path, setPath] = useState(id.split('-'));
  const [tests, setTests] = useState(null);
  const [subBlocks, setSubBlocks] = useState(null);
  const [name, setName] = useState('');

  const getData = async () => {
    const response = await axiosConfig(2).get('survey/api/blocks/');
    if ('data' in response) {
      const data = response.data;
      let blocks = data;
      for (const idBlock of path) {
        const block = blocks.find((b) => b.id == idBlock);
        setName(block);
        if (block) {
          blocks = block.blocks;
        } else {
          blocks = [];
          break;
        }
      }
      if (blocks.length !== 0) {
        setSubBlocks(blocks);
      } else {
        setSubBlocks(null);
        const responseTests = await axiosConfig(2).get(
          `survey/api/blocks/${path[path.length - 1]}/tests/`
        );
        if ('data' in responseTests) {
          setTests(responseTests.data);
        }
      }
    }
  };

  useEffect(() => {
    getData();
    setSubBlocks(null);
    setCurrentId(id.split('-'));
  }, [id]);

  return (
    <>
      <button onClick={() => navigate(-1)} className={styles.button}>
        {'<--'}Назад
      </button>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>{name?.text}</h1>
        <div className={styles.wrapperList}>
          {subBlocks &&
            subBlocks?.map((item) => {
              return <a href={`/studypage/${id}-${item.id}`}>{item.text}</a>;
            })}
          {tests &&
            tests?.map((item) => (
              <Link to={`/stydypage/${id}/themepage/${item.id}`}>
                {item.text}
              </Link>
            ))}
        </div>
      </div>
      <MatrixRain></MatrixRain>
    </>
  );
}

export default StudyPlanPage;
