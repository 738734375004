import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import styles from './ProfilePage.module.css';

import { MatrixRain } from '../../components/MatrixRain/MatrixRain';

export const ProfilePage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <div className={styles.navigator}>
        <div className={styles.navigator_box}>
          <Link to={'/study/'}> В матрицу </Link>
          <Link to={'/work/'}>Работа.</Link>
          <Link to={'/studylist/'}>Учёба.</Link>
          <Link> В гости </Link>
          <Link> В мир. </Link>
          <div className={styles.gamburger} onClick={() => setOpen(!open)}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className={`${open ? styles.active : styles.menu}`}>
          <Link to={'/study/'}> В матрицу </Link>
          <Link to={'/work/'}>Работа.</Link>
          <Link to={'/studylist/'}>Учёба.</Link>
          <Link> В гости </Link>
          <Link> В мир. </Link>
        </div>
      </div>
      <button onClick={() => navigate(-1)} className={styles.button}>
        {'<--'}Назад
      </button>{' '}
      <MatrixRain></MatrixRain>
    </>
  );
};
