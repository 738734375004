import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import styles from './Menu.module.css';

import { MatrixRain } from '../../components/MatrixRain/MatrixRain';
import { staticLinks } from '../../config/routingsLinks';

const Menu = () => {
  const navigate = useNavigate();
  return (
    <div>
      <button onClick={() => navigate(-1)} className={styles.button}>
        {'<--'}Назад
      </button>{' '}
      <div className={styles.linksWrapper}>
        <Link className={styles.link} to={staticLinks.work}>
          {' '}
          <span>1)</span> Проекты.
        </Link>
        <Link className={styles.link} to={''}>
          {' '}
          2) Книги.
        </Link>
        <Link className={styles.link} to={''}>
          {' '}
          3) Храм.
        </Link>
        <Link className={styles.link} to={''}>
          {' '}
          4) О нас.
        </Link>
        <Link className={styles.link} to={''}>
          {' '}
          5) Сотрудничество.
        </Link>
        <Link></Link>
      </div>
      <MatrixRain></MatrixRain>
    </div>
  );
};

export default Menu;
