import React from 'react'

import styles from "./Galary.module.css"

// import image from "../../../../../../assets/images/Pont.jpg"
// import image1 from "../../../../../../assets/images/prestol/11_1.png"
// import image2 from "../../../../../../assets/images/prestol/4.png"
// import image3 from "../../../../../../assets/images/prestol/44.png";
// import image4 from "../../../../../../assets/images/prestol/5.png";
// import image5 from "../../../../../../assets/images/prestol/55.png";

import image1 from "../../../../../../assets/images/prestol/11_1.png";
import image2 from "../../../../../../assets/images/prestol/4.png";
import image3 from "../../../../../../assets/images/prestol/44.png";
import image4 from "../../../../../../assets/images/prestol/5.png";
import image5 from "../../../../../../assets/images/prestol/55.png";
import image6 from "../../../../../../assets/images/001.jpg";
import image7 from "../../../../../../assets/images/001.jpg";
import image8 from "../../../../../../assets/images/001.jpg";
import image9 from "../../../../../../assets/images/001.jpg";
import { useNavigate } from 'react-router';
// import { useNavigate } from "react-router-dom";


// const Galary = () => {
//   return (
//     <div className={styles.wrapper}>
//       <img src={image} alt="Поменяйте комп." />
//       <img src={image1} alt="Поменяйте комп." />
//       <img src={image3} alt="Поменяйте комп." />
//       <img src={image4} alt="Поменяйте комп." />
//       <img src={image5} alt="Поменяйте комп." />
//       <img src={image} alt="Поменяйте комп." />
//       <img src={image3} alt="Поменяйте комп." />
//       <img src={image2} alt="Поменяйте комп." />
//       <img src={image} alt="Поменяйте комп." />
//       <img src={image} alt="Поменяйте комп." />
//       <img src={image3} alt="Поменяйте комп." />
//     </div>
//   );
// }

const Galary = () => {
  const handleClick = (id) => {
    const element = document.getElementById(`item-${id}`);
    if (!element) return;

    if (element.classList.contains("expanded")) {
      element.classList.remove("expanded");
    } else {
      element.classList.add("expanded");
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const navigate = useNavigate();

  return (
    <>
      <div className={styles.wrapp}>Смысловое название.</div>
      <div className={styles.wrapper}>
        <img src={image1} alt="" id="item-1" onClick={() => handleClick("1")} />
        <img src={image2} alt="" id="item-2" onClick={() => handleClick("2")} />
        <img src={image3} alt="" id="item-3" onClick={() => handleClick("3")} />
        <img src={image4} alt="" id="item-4" onClick={() => handleClick("4")} />
        <img src={image5} alt="" id="item-5" onClick={() => handleClick("5")} />
        <img src={image6} alt="" id="item-6" onClick={() => handleClick("6")} />
        <img src={image7} alt="" id="item-7" onClick={() => handleClick("7")} />
        <img src={image8} alt="" id="item-8" onClick={() => handleClick("8")} />
        <img src={image9} alt="" id="item-9" onClick={() => handleClick("9")} />
        <button onClick={() => navigate(-1)} className={styles.button}>
          {"<--"}Назад
        </button>
      </div>
    </>
  );
};

export default Galary