import React from 'react'


import styles from "./About.module.css"
import { Link, useNavigate } from 'react-router-dom';
import { staticLinks } from '../../../../../../config/routingsLinks';


const AboutDavid = () => {
const navigate = useNavigate();
  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <Link>Описание.</Link>
        <p>
          Здравствуйте. Вы должны понимать. То, кем вы себя считаете, должны
          этому и соответствовать. Согласитесь, самообман дорога в некуда. Лично
          я мерею вас по вашим привычкам, манерам, словам. Разумеется, если я
          увижу, услышу, или узнаю, что-то что противоречит моим ценностям, мы
          попрощаемся. Далее вы пойдёте с себе подобными и как итог, забвение с
          последствиями. Я молюсь за ваше процветание и ваших детей. Возглавить
          проект вы сможете если ответите на мои вопросы.
        </p>
      </div>
      <div className={styles.innerWrapper}>
        <Link to={staticLinks.davidLeadProgect}>Возглавить проект.</Link>
      </div>
      <button onClick={() => navigate(-1)} className={styles.button}>
        {"<--"}Назад
      </button>{" "}
    </div>
  );
};

export default AboutDavid