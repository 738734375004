import React from 'react';
import { Link, useParams } from 'react-router-dom';

import style from './VerdictPage.module.css';

import { MatrixRain } from '../../components/MatrixRain/MatrixRain';
import { staticLinks } from '../../config/routingsLinks';

const verdictData = {
  1:
    'Первая книга. Вердикт один.\n' +
    'Убийству нет прощения. Противящийся злу, дополняет зло.\n' +
    'Убийство во имя защиты, правды, отечества, есть нарушение.\n' +
    'Убийство во Имя Моё, есть нарушение. Прощения за это нет и не будет.\n' +
    'Простил убийство, стал убийцей.\n' +
    'Самоубийство - тоже убийство.\n' +
    'Пособничество в убийстве, убийство.\n' +
    'Отправил убивать, стал убийцей.\n' +
    'На земле нет того, кто простит убийство!',
  2:
    'Вторая книга. Вердикт два.\n' +
    'Разводящиеся без вины прелюбодеяния.\n' +
    'Разведённые, без вины прелюбодеяния в ад.\n' +
    'Обручающиеся с разведёнными по вине прелюбодеяния, запрещено!.\n' +
    'Рукоблудники, всяк извращённый, извращенец и сменивший пол, горите там же где и убийцы.\n' +
    'Священники освобождаются от прощений всяких грехов.',
  3:
    'Третья книга. Вердикт три.\n' +
    'Нанесение рисунков под кожу (Татуировки или ещё, какое название выдумаете), запрещено.\n' +
    'Пьющие спиртное, хоть самую малость, запрещено.\n' +
    'Курящие, употребляющие всякое дурманящее, матершинники, сквернословящие, пререкающиеся, спорящие,.\n' +
    'туда-же, где и разведённые по вине прелюбодеяния.',
  4:
    'Четвёртая книга. Вердикт четыре.\n' +
    'Колдуны. Чародеи. Гадалки. Астрологи. Маги.\n' +
    'Духовызыватели, все в гиену огненную к отцу своему.',
  5:
    'Пятая книга. Вердикт пять.\n' +
    'Фараоны. Цари. Князья. Прочие главенствующие. Президенты. Главы земель (Страны).',
  6:
    'Шестая книга. Вердикт шесть.\n' +
    'Лжепророки. Иконы, ложные боги (Материальное).\n' +
    'Лжеотцы: Опекунов и кровных отцов, можно называть отцами, отец.\n' +
    'Лжеучителя: Дающие советы как жить людям.\n' +
    'Лжесвященники: Патриархи, их замы, все нарушители в длинных одеждах.\n' +
    'Раскольники: Все те, кто виновен в расколе.',
  7:
    'Седьмая книга. Вердикт семь\n' +
    'Лжерелигии: Индуиская. Мусульманская. Иудейская. Католики.\n' +
    'Православные. Старообрядцы. Всех напишите, кто \n' +
    'потакал малейшему злу.',
  8:
    'Восьмая книга. Вердикт восемь\n' +
    'В эту книгу всех Николаитов. Производители спиртного и всякого дурманящего,\n' +
    'делающих вещей для извращенцев.\n' +
    'Напишите прочих несущих злодейства.\n' +
    'По словам разбойника, банкиры, заводы, отравляющие природу, землю и детей Моих. Всех в эту книгу',
  9:
    'Книга девять. Вердикт девять.\n' +
    'Мясоеды, людоеды. Убийцы флоры и фауны.',
  10:
    'Книга десять. Вердикт десять.\n' +
    'Друзья разбойника. Жители города. Праведники.\n' +
    'Сто сорок четыре тысячи девственников мужского пола, тех, кто согласились идти за Мной.\n' +
    'Запечатлённые. Помилованные.',
};

const VerdictPage = () => {
  const { id } = useParams();

  return (
    <>
      <div className={style.wrapper}>{verdictData[id]}</div>
      <MatrixRain></MatrixRain>
      <div className={style.buttons}>
        <Link to={staticLinks.planet}>Назад</Link>
        <Link to={staticLinks.mainbookpage}>Книги</Link>
      </div>
    </>
  );
};

export default VerdictPage;
