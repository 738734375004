import React from 'react'

import styles from "./David.module.css"
import { Link, useNavigate } from 'react-router-dom'
import { staticLinks } from '../../../../../config/routingsLinks'

const David = () => {
const navigate=useNavigate()
  return (
    <>
    <div className={styles.wrapper}>
    <Link to={staticLinks.aboutDavid}>Описание.</Link>
    <Link to={staticLinks.davidGalary}>Фотографии</Link>
    <Link>Предложения</Link>
    </div>
    <button onClick={() => navigate(-1)} className={styles.button}>
    {"<--"}Назад
    </button>{" "}
    </>
  )
}
export default David