// @ts-ignore
import styles from './ProgressBar.module.scss';



export const ProgressBar = ({
	currentQuestion,
	countOfUserAnswers,
	countOfQuestions,
}) => {
	const progressWidth = (100 / countOfQuestions) * countOfUserAnswers;

	return (
		<div className={styles.wrapper}>
			<p className={styles.text}>
				Вопрос <span>{currentQuestion}</span> из <span>{countOfQuestions}</span>
			</p>
			<div className={styles.bar}>
				<div className={styles.progress} style={{ width: `${progressWidth}%` }}></div>
			</div>
		</div>
	);
};
