import React from 'react'
import { Link } from 'react-router-dom'

import styles from "./HellPage.module.css"

const HellPage = () => {
  return (
    <div className={styles.wrapper}>
      <textarea className={styles.textarea}>
        You were warned. You have neglected. Now I'm going to stop your hearts.
        You don't have a chance. Stop for the sake of what is dear to you.
      </textarea>
      <div className={styles.wrappe}>
        <Link to={"/books/list/"}>Имена. The names.</Link>
      </div>
    </div>
  );
}

export default HellPage