import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import styles from './ProjectsFond.module.css';

const ProjectsFond = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.wrapper}>
        <button onClick={() => navigate(-1)} className={styles.button}>
          {'<--'}Назад
        </button>{' '}
        <Link to={'Traces/'}>Следы Эпох.</Link>
      </div>
      <div className={styles.wrapper2}>
        <button onClick={() => navigate(-1)} className={styles.button}>
          {'<--'}Назад
        </button>{' '}
        <Link to={'/David/'}>Престол Давида.</Link>
      </div>
      <div className={styles.wrapper3}>
        <button onClick={() => navigate(-1)} className={styles.button}>
          {'<--'}Назад
        </button>{' '}
        <Link to={'Traces/'}>Искуственый Интелект.</Link>
      </div>
      <div className={styles.wrapper4}>
        <button onClick={() => navigate(-1)} className={styles.button}>
          {'<--'}Назад
        </button>{' '}
        <Link to={'Traces/'}>Матрица.</Link>
      </div>
      <div className={styles.wrapper5}>
        <button onClick={() => navigate(-1)} className={styles.button}>
          {'<--'}Назад
        </button>{' '}
        <Link to={'Traces/'}>Акведуки.</Link>
      </div>
    </>
  );
};

export default ProjectsFond;
