import React, { useState } from 'react';

import styles from './LightPage.module.css';

import img from '../../../assets/images/Папирус_5.jpg';
import img2 from '../../../assets/images/Папирус_A1_2.jpg';
import img3 from '../../../assets/images/Папирус_A3_1.jpg';
import img4 from '../../../assets/images/Папирус_A3_3.jpg';
import img5 from '../../../assets/images/Папирус_A3_4.jpg';

import 'swiper/css';

import { Link, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

const LightPage = () => {
  const [isVizible, setIsVizible] = useState(false);
  function hendleopenmodal() {
    setIsVizible(!isVizible);
  }
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.wrapper}>
        <div>{}</div>
        <div className={styles.navigator}>
          <Link>Календари</Link>
          <Link>Пакет.</Link>
          <Link>Коробки</Link>
          <Link>Печать</Link>
          <Link>Контакты</Link>
          <button
            onClick={() => hendleopenmodal()}
            className={styles.menubutton}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>{' '}
        </div>
        {isVizible && (
          <div className={styles.modal}>
            <Link>Календари</Link>
            <Link>Пакет.</Link>
            <Link>Коробки</Link>
            <Link>Печать</Link>
            <Link>Контакты</Link>
          </div>
        )}
        {/* <div className={styles.intro}></div> */}
        <div className={styles.list}>
          <Link style={{ maxWidth: '300px' }} to={'product/1'}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                ></Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt='' />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  'https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/'
                }
              >
                Карточка 1
              </a>
            </div>
          </Link>
          <Link style={{ maxWidth: '300px' }} to={'product/1'}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                ></Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img4} alt='' />
                </Link>
              </SwiperSlide>{' '}
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img5} alt='' />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  'https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/'
                }
              >
                Карточка 2
              </a>
            </div>
          </Link>
          <Link style={{ maxWidth: '300px' }} to={'product/1'}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                ></Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt='' />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  'https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/'
                }
              >
                Карточка 3
              </a>
            </div>
          </Link>
          <Link style={{ maxWidth: '300px' }} to={'product/1'}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                ></Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt='' />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  'https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/'
                }
              >
                Карточка 4
              </a>
            </div>
          </Link>
          <Link style={{ maxWidth: '300px' }} to={'product/1'}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                ></Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt='' />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  'https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/'
                }
              >
                Карточка 5
              </a>
            </div>
          </Link>
          <Link style={{ maxWidth: '300px' }} to={'product/1'}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                ></Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt='' />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  'https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/'
                }
              >
                Карточка 6
              </a>
            </div>
          </Link>
          <Link style={{ maxWidth: '300px' }} to={'product/1'}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                ></Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt='' />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  'https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/'
                }
              >
                Карточка 7
              </a>
            </div>
          </Link>
          <Link style={{ maxWidth: '300px' }} to={'product/1'}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                ></Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt='' />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  'https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/'
                }
              >
                Карточка 8
              </a>
            </div>
          </Link>
          <Link style={{ maxWidth: '300px' }} to={'product/1'}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                ></Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt='' />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  'https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/'
                }
              >
                Карточка 9
              </a>
            </div>
          </Link>
          <Link style={{ maxWidth: '300px' }} to={'product/1'}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                ></Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt='' />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  'https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/'
                }
              >
                Карточка 10
              </a>
            </div>
          </Link>
          <Link style={{ maxWidth: '300px' }} to={'product/1'}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                ></Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt='' />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  'https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/'
                }
              >
                Карточка 11
              </a>
            </div>
          </Link>
          <Link style={{ maxWidth: '300px' }} to={'product/1'}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                ></Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt='' />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  'https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/'
                }
              >
                Карточка 12
              </a>
            </div>
          </Link>
          <Link style={{ maxWidth: '300px' }} to={'product/1'}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                ></Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt='' />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  'https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/'
                }
              >
                Карточка 13
              </a>
            </div>
          </Link>
          <Link style={{ maxWidth: '300px' }} to={'product/1'}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                ></Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt='' />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  'https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/'
                }
              >
                Карточка 14
              </a>
            </div>
          </Link>
          <Link style={{ maxWidth: '300px' }} to={'product/1'}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              className={styles.swiper}
            >
              <SwiperSlide>
                <Link
                  className={styles.cart}
                  // to={"http://000000000000.ru/fond/1"}
                ></Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img2} alt='' />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className={styles.cart}>
                  <img src={img3} alt='' />
                </Link>
              </SwiperSlide>
            </Swiper>
            <div className={styles.text}>
              <a
                href={
                  'https://www.ozon.ru/product/bumaga-dlya-risovaniya-a4-21-29-7-sm-2-list-sht-1526997244/'
                }
              >
                Карточка 15
              </a>
            </div>
          </Link>
        </div>
        <div className={styles.footer}></div>
      </div>

      <div>ООО "Свет"</div>
    </>
  );
};

export default LightPage;
