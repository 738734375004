import React from 'react';
import { Carousel } from 'react-round-carousel';
import { useNavigate } from 'react-router-dom';

import styles from './Product.module.css';

import image1 from '../../../../assets/img/hj.jpg';
import image4 from '../../../../assets/img/Папирус_A3_2.jpg';
import image2 from '../../../../assets/img/Папирус_A4_3.jpg';
import image3 from '../../../../assets/img/Папирус_A4_3.jpg';

import './Product.css';

import { MatrixRain } from '../../../../components/MatrixRain/MatrixRain';

const Traces = () => {
  const navigate = useNavigate();
  const [bookId, setBookId] = React.useState(1);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [scaleBook, setScaleBook] = React.useState(false);
  const carouselRef = React.useMemo(
    () => (node) => {
      if (node !== null) {
        let bookId = (node.getSelectedIndex() + 1) % items.length;
        if (Math.abs(bookId) === 0) bookId = items.length;
        localStorage.setItem('bookId', bookId);
        setBookId(bookId);
      }
    },
    []
  );

  const handleClick = (id) => {
    const element = document.getElementById(`item-${id}`);
    if (!element) return;

    if (element.classList.contains('expanded')) {
      element.classList.remove('expanded');
    } else {
      element.classList.add('expanded');
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const items = [
    {
      alt: 'A random photo',
      image: image1,
      content: (
        <div
          id='item-1'
          className='test'
          onClick={() => handleClick(1)}
          style={{ width: '400px', height: '400px' }}
        >
          <img src={image1} alt='A random photo' />
        </div>
      ),
    },
    {
      alt: 'A random photo',
      image: image2,
      content: (
        <div
          id='item-2'
          className='test'
          onClick={() => handleClick(2)}
          style={{ width: '400px', height: '400px' }}
        >
          <img src={image2} alt='A random photo' />
        </div>
      ),
    },
    {
      alt: 'A random photo',
      image: image3,
      content: (
        <div
          id='item-3'
          className='test'
          onClick={() => handleClick(3)}
          style={{ width: '400px', height: '400px' }}
        >
          <img src={image3} alt='A random photo' />
        </div>
      ),
    },
    {
      alt: 'A random photo',
      image: image4,
      content: (
        <div
          id='item-4'
          onClick={() => handleClick(4)}
          style={{ width: '400px', height: '400px' }}
        >
          <img src={image4} alt='A random photo' />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.list}>
          <div className='product-carousel'>
            <Carousel
              itemWidth={400}
              ref={carouselRef}
              items={items}
              slideOnClick
              // classNamePrefix="product-carousel"
            />
          </div>
          <div className={styles.wrappe}>
            <textarea rows={10} cols={50}>
              Ключевая особенность папируса в том, что он долговечен, на что
              собственно я делаю упор в проекте. Данная с страница на этапе
              формирования. В случае возникновения интереса, напишите, обсудим
              на сколько можем быть интересны друг другу.imperiya66.ru@gmail.com
            </textarea>
          </div>
        </div>
        <button onClick={() => navigate(-1)} className={styles.button}>
          {'<--'}Назад
        </button>
        <div className={styles.footer}></div>
      </div>
      <MatrixRain></MatrixRain>
    </>
  );
};

export default Traces;
