import React from 'react';
import { Link, useParams } from 'react-router-dom';

import style from './СommandmentPage.module.css';

import { MatrixRain } from '../../components/MatrixRain/MatrixRain';
import { staticLinks } from '../../config/routingsLinks';

const сommandmentData = {
  1:
    'Я, Всевышний Иисус Христос, Бог твой, Который Привёл тебя в Дом Свой \n' +
    'как Клялся отцам твоим народу Моему Израилю. \n' +
    'Да не будет у вас иных богов Пред Лицом Моим.',
  2: 'Заповедь новую Даю вам: любите друг друга как Я Полюбил вас.',
  3: 'Не убивай ни человека, ни зверя. Ничего не убивай.',
  4: 'Не прелюбодействуй.',
  5: 'Не кради. Не желай дома ближнего твоего, ни поля его, ни раба его, ничего, что у ближнего твоего. Не желай чужого.',
  6: 'Не произноси ложного свидетельства на ближнего твоего.',
  7: 'Не произноси Имени Моего напрасно, ибо не Оставлю без наказания того кто произносит Имя Моё напрасно.',
  8:
    'Не делай себе кумира и никакого изображения того, что на небе и на земле; \n' +
    'не поклоняйся им и не служи им, ибо Я, Всевышний Иисус Христос, Бог твой, \n' +
    'Бог Ревнитель Наказывающий детей за вину отцов до третьего и четвёртого рода, \n' +
    'ненавидящих Меня, и Творящий Милость \n' +
    'до тысячи родов любящим Меня и соблюдающим Завет Мой Вечный.',
  9: 'Почитай отца твоего и мать твою.',
  10:
    'Помни день субботний чтобы святить его; шесть дней работай и делай в них всякие дела твои,\n' +
    'а день седьмой – суббота\n' +
    'Всевышнему Иисусу Христу, Богу твоему: не делай в оный никакого дела.',
};

const СommandmentPage = () => {
  const { id } = useParams();
  return (
    <>
      <div className={style.wrapper}>{сommandmentData[id]}</div>
      <MatrixRain></MatrixRain>
      <div className={style.buttons}>
        <Link to={staticLinks.planet}>Назад</Link>
        <Link to={staticLinks.mainbookpage}>Книги</Link>
      </div>
    </>
  );
};

export default СommandmentPage;
