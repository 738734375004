import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import styles from './Fond.module.css';

import { MatrixRain } from '../../../components/MatrixRain/MatrixRain';

const Fond = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.wrapper}>
        <Link to={'/study/'}>В матрицу</Link>
        <Link to={'/fond/1/requisites/'}>Реквизиты.</Link>
        <Link to={'about/'}>О нас.</Link>
        <Link to={'ProjectsFond/'}>Проекты.</Link>
        <Link to={'/profile/'}> Домой. </Link>
      </div>
      <button onClick={() => navigate(-1)} className={styles.button}>
        {'<--'}Назад
      </button>{' '}
      <div className={styles.wrappe}>
        <textarea>
          Данная с страница на этапе формирования. В случае возникновения
          интереса, напишите, обсудим на сколько можем быть интересны друг
          другу.imperiya66.ru@gmail.com
        </textarea>
      </div>
      <MatrixRain></MatrixRain>
    </>
  );
};

export default Fond;
