import React from 'react';
import HTMLFlipBook from 'react-pageflip';

import styles from './Book.module.css';

export const Book = () => {
  return (
    <div className={styles.wrapper}>
      <HTMLFlipBook width={630} height={700} className={styles.book}>
        <div className={styles.firstPage}>
          <textarea name='' id=''>
            Привет привет привет привет
          </textarea>
        </div>
        <div className={styles.secondPage}>
          <textarea name='' id=''>
            Привет привет привет привет
          </textarea>
        </div>
        <div className={styles.thirdPage}>
          <textarea name='' id=''>
            Дима, это победа.
          </textarea>
        </div>
        <div className={styles.secondPage}>
          <textarea name='' id=''>
            Привет Дима, это победа.
          </textarea>
        </div>
        <div className={styles.firstPage}>Page 5</div>
        <div className={styles.secondPage}>Page 6</div>
        <div className={styles.firstPage}>Page 7</div>
        <div className={styles.secondPage}>Page 8</div>
      </HTMLFlipBook>
    </div>
  );
};
