export const staticLinks = {
  main: '/',
  commandment: '/commandment/:id',
  finance: '/finance',
  keys: '/keys',
  login: '/login',
  mail: '/mail',
  planet: '/planet',
  profile: '/profile',
  register: '/register',
  study: '/study',
  verdict: '/verdict/:id',
  votes: '/votes',
  work: '/work',
  studylist: '/studylist',
  studypage: '/studypage/:id',
  themepage: '/stydypage/:id/themepage/:childid',
  lessonpage: '/stydypage/:id/themepage/:childid/lessonpage/:lessonid',
  mainbookpage: '/books/',
  bookspage: '/books/list/',
  bookpage: '/books/books/id',
  personalpage: '/books/personalpage/',
  mabooks: '/books/mabooks/',
  constuctor: '/books/constuctor/',
  portfoliopage: '/portfolio/',
  fond: '/fond/:id',
  hellpage: '/hellpage/',
  about: '/fond/:id/about/',
  requisites: '/fond/:id/requisites/',
  lightpage: '/fond/:id/lightpage/',
  projectsfond: '/fond/:id/ProjectsFond/',
  traces: '/fond/:id/ProjectsFond/Traces/',
  product: '/fond/:id/lightpage/product/:id',
  menu: '/menu',
  trecesbooks: '/treces/books/:id',
  trecesollbooks: '/treces/books/',
  david: '/david/',
  aboutDavid: '/aboutDavid/',
  davidLeadProgect: '/david/leadProgect/',
  davidGalary: '/david/galery/',
};

export const dynamicLinks = {
  commandment: (id) => `/commandment/${id}`,
  verdict: (id) => `/verdict/${id}`,
  studypage: (id) => `/studypage/${id}`,
  themepage: (id) => `/themepage/${id}`,
  trecesbooks: (id) => `/treces/books/${id}`,
};
